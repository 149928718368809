import React from 'react'
import './Firm.scss'
import { Carousel } from 'react-bootstrap'
import firm from "../../assets/Videos/ACL _ Welcome Page _ Main Page _ First (1).mp4"

const Firm = () => {
  return (
    <div className="firm">
      <Carousel className="custom-carousel" controls={false}>
        <Carousel.Item>
          <video className="d-block w-100" autoPlay loop muted>
            <source src={firm} type="video/mp4" />
          </video>
          <div className="carousel-text">
            <p>
              Our Firm
            </p>
          </div>
          {/* <img className="d-block w-100 " src={image1} alt="First slide" /> */}
        </Carousel.Item>
      </Carousel>
     
      <div className="firm__content">
        <h2 className="firm__title">How We Work With You</h2>
        <p className="firm__description">
          We will meet with you, review your case, and advise you. We tailor our
          advice to your unique individual needs and circumstance. We foster a
          close working relationship with you so you are aware and also
          understand, at all times, the status of your matter and what the next
          steps are. This is important for us to maintain and deliver on the
          trust you have bestowed to us.
        </p>
        <h2 className="firm__title">How We Achieve Results for You!</h2>
        <p className="firm__description">
          In Ontario, the litigation process is designed in a manner that
          facilitates dispute resolution through a variety of methods. The
          majority of disputes in all practice areas resolve in some form of
          settlement prior to trial. Our proven experience has helped us to
          develop proven litigation techniques which we employ to negotiate and
          achieve optimal settlements for our clients. We are committed to the
          application of our proven litigation techniques for ALL of our
          clients. For matters that demonstrate an inability to settle, we have
          the proven skills and necessary experience required to win your case
          in Court.
        </p>
      </div>
    </div>
  );
}

export default Firm