import React, { useRef, useState } from "react";
import "./Contact.scss";
import { Form, Button } from "react-bootstrap";
import emailjs from "emailjs-com";

const Contact = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_oms0d5w",
        "template_qlngmff",
        form.current,
        "Pfg8GV8q2vNJBVLtA"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccessMessage(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset(); //resets form after submission
  };

  return (
    <div className="contact-page">
      <div className="contact-details">
        <div className="contact-form">
          <h2>Contact Us</h2>
          <h4>CALL US FOR A FREE 30-MINUTE CONSULTATION</h4>
          <p>
            The Aeon Capital team is here to answer any inquiries you may have
            about our services or about an account. Send us an email, give us a
            call, or complete our contact form, and one of our representatives
            will be in touch with you within 24 business hours.
          </p>
          <Form ref={form} onSubmit={sendEmail}>
            <Form.Group className="mb-3">
              <Form.Label>Your Name *</Form.Label>
              <Form.Control type="text" name="name" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email *</Form.Label>
              <Form.Control type="email" name="email" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" name="message" rows={5} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
          {showSuccessMessage && (
            <p style={{ color: "green" }}>Message sent successfully.</p>
          )}
        </div>

        <div className="company-details">
          <h3>AEON LAW CAPITAL OFFICES</h3>
          <p>2680 Matheson Blvd East, Suite 102</p>
          <p>Mississauga, ON. L4W 0A5</p>
          <p>Toll Free: 1 (800) 680-4358</p>
          <p>Local: (905) 306-9898</p>
          <p>Fax: 905-306-9898</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
