import React from 'react'
import { Link } from 'react-router-dom';
import Ban from "../../assets/images/legal.jpg"
import "./Banner.scss"


const Banner = () => {
  return (
    <div className="banner">
      {/* You can replace 'banner-image.jpg' with the actual image URL */}
      <img
        src={Ban}
        alt="Need Help Banner"
        className="banner-image"
      />
      <div className="banner-content">
        <h2 className='heading'>Need Help?</h2>
        <p className="help__contents--heading">
          Contact our customer support team if you have any further questions.
          We are here to help you out
        </p>
        <p className="help__Contents--contact">
          <span className="help__number">(905) 306 - 9898</span> |
          info@aeoncapital.ca
        </p>
        <Link to="/contact-us" className="contact-us-button">
          Contact Us
        </Link>
      </div>
    </div>
  );
}

export default Banner