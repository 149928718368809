import React from "react";
 import "./PracticeAreas.scss";

const PracticeAreas = () => {
  const practiceAreas = [
    {
      title: "Civil Litigation",
      description:
        "The civil litigation process in Ontario is complex and follows a structured series of steps that parties go through to resolve disputes through the courts.",
      imageUrl: "/images/CivilLitigation.jpg",
      link: "/civil-litigation",
    },
    {
      title: "Corporate Commercial Law",
      description:
        "A corporate commercial law practice in Ontario, Canada, involves providing legal services and advice to businesses, corporations...",
      imageUrl: "/images/ccl.png",
      link: "/corporate-commercial-law",
    },
    {
      title: "Insurance Law",
      description:
        "Aeon Capital Law insurance law practice involves providing legal services and advice related to various aspects of insurance...",
      imageUrl: "/images/insurance-law.jpg",
      link: "/insurance",
    },
    {
      title: "Estate Law",
      description:
        "Aeon Capital Law estate law practice involves providing legal services and advice related to matters concerning estates...",
      imageUrl: "/images/EstateLaw.jpg",
      link: "/estate-law",
    },
    {
      title: "Privacy Law",
      description:
        "Our privacy law practice is focused on advising businesses and organizations on privacy rights, data protection...",
      imageUrl: "/images/privacy.jpg",
      link: "/privacy-law",
    },
  ];

  return (
    <>
      <h1 className="heading">Our Practice Areas</h1>
      <div className="practice-area">
        {practiceAreas.map((area, index) => (
          <div key={index} className="card">
            <img src={area.imageUrl} alt={area.title} />
            <h3>{area.title}</h3>
            <p>{area.description}</p>
            <a href={area.link}>
              <button>Learn More</button>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default PracticeAreas;
