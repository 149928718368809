import React from "react";
import Header from "../../components/Header/Header";
import Slider from "../../components/Slider/Slider";
import Content from "../../components/Content/Content";
import PracticeAreas from "../Practice-Areas/PracticeAreas";
import NeedHelp from "../../components/NeedHelp/NeedHelp";
import Banner from "../../components/Banner/Banner";
import TestimonialSlider from "../../components/Testimony/TestimonialSlider";
import Intro from "../../components/Content/Intro";
const Home = () => {
  return (
    <>
      <Slider />
      {/* <Content /> */}
      <Intro/>
      <PracticeAreas />

      {/* <NeedHelp/> */}
      <TestimonialSlider />
      <Banner />
    </>
  );
};

export default Home;
