import "./App.css";
import Terms from "./components/TermsOfUse/Terms";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Header from "./components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Firm from "./pages/Firm/Firm";
import Civil from "./pages/Civil/Civil";
import Insurance from "./pages/Insurance/Insurance";
import CorporateCLaw from "./pages/Corporate/CorporateCLaw";
import EstateLaw from "./pages/EstateLaw/EstateLaw";
import Privacy from "./components/Privacy/Privacy";
import Contact from "./pages/Contact/Contact";
import PrivacyLaw from "./pages/PrivacyLaw/PrivacyLaw";


function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/our-firm" element={<Firm />} />
          <Route path="/civil-litigation" element={<Civil />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/corporate-commercial-law" element={<CorporateCLaw />} />
          <Route path="/estate-law" element={<EstateLaw />} />
          <Route path="/privacy-law" element={<PrivacyLaw />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          {/* <Route path="/about-us" element={<Terms />} />

          <Route path="/our-firm" element={<Terms />} />
          <Route path="/practice-areas" element={<Terms />} />
          <Route path="/privacy-law" element={<Terms />} />
          <Route path="/estate-law" element={<Terms />} />
          
          <Route path="/corporate-commercial-law" element={<Terms />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
