import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "../../styles/styles.scss";
import Pri from "../../assets/Videos/PrivacyLaw.mp4";
import { Link } from "react-router-dom";
import Contact from "../Contact/Contact";

const Section = ({ title, content, isExpanded, onToggle }) => {
  return (
    <div>
      <h6 className="corporate__title" onClick={onToggle}>
        {title} {isExpanded ? "-" : "+"}
      </h6>
      {isExpanded && (
        <ul className="corporate__lists">
          <li className="corporate__list">{content}</li>
        </ul>
      )}
    </div>
  );
};

const PrivacyLaw = () => {
  const sectionsData = [
    {
      title: "Privacy Legislation",
      content:
        "Advising clients on compliance with privacy laws and regulations in Ontario, including, amongst others: the Personal Information Protection and Electronic Documents Act (PIPEDA) or its successor legislation the Consumer Privacy Protection Act (CPPA) its companion legislation, Artificial Intelligence Data Act (AIDA) and the Ontario Personal Health Information Protection Act (PHIPA).",
    },
    {
      title: "Data Protection and Security",
      content: (
        <div>
          <p>
            Assisting businesses in developing and implementing data protection
            policies and procedures to safeguard personal information.
          </p>
          <li>
            Advising on data breach response plans and notification requirements
            in case of a security incident.
          </li>
        </div>
      ),
    },
    {
      title: "Privacy Policies and Notices",
      content: (
        <div>
          <p>
            Drafting and reviewing privacy policies and notices to ensure
            transparency and compliance with applicable laws.
          </p>
          <li>
            Advising on the collection, use, and disclosure of personal
            information.
          </li>
        </div>
      ),
    },
    {
      title: "Bad Faith Claims",
      content:
        "Advising policyholders on potential claims against insurers for acting in bad faith, such as unreasonably denying valid claims or delaying claim processing.",
    },
    {
      title: "Consent Management",
      content: (
        <div>
          <p>
            Advising on obtaining proper consent for collecting, using, and
            disclosing of personal information
          </p>
          <li>
            Developing consent forms and mechanisms that meet legal requirements
          </li>
        </div>
      ),
    },
    {
      title: "Healthcare Privacy",
      content:
        "Advising healthcare providers on compliance with privacy laws, especially the Ontario Personal Health Information Protection Act (PHIPA).",
    },
    {
      title: "Privacy Impact Assessments",
      content:
        "Conducting privacy impact assessments to identify and mitigate privacy risks in new applications, projects, technologies, or processes.",
    },
    {
      title: "Legal Support for Data Breaches",
      content:
        "Assisting organizations in responding to data breaches, including notifying affected individuals and regulatory authorities",
    },
    {
      title: "Technology Contracts and Agreements",
      content:
        "Drafting and reviewing contracts related to data sharing, data processing, and data protection.",
    },
  ];
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };
  return (
    <div className="corporate">
      <Carousel className="custom-carousel" controls={false}>
        <Carousel.Item>
          <video className="d-block w-100 " autoPlay loop muted>
            <source src={Pri} type="video/mp4" />
          </video>
          <div className="carousel-text">
            <h2>
              We help individuals and businesses navigate the complex landscape
              of privacy regulations and digital security threats.
            </h2>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="corporate__description">
        <h4>Privacy Law</h4>
        <p>
          Our privacy law practice is focused on advising businesses and
          organizations on privacy rights, data protection, and compliance with
          privacy laws and regulations. With the increasing importance of
          digital data and technology, privacy has become a critical concern for
          individuals, businesses, and organizations. We help businesses or
          organizations to comply with privacy laws and regulations in:
        </p>
      </div>
      <div className="corporate__contents">
        {sectionsData.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
            isExpanded={expandedSection === index}
            onToggle={() => toggleSection(index)}
          />
        ))}
      </div>
      <p className="corporate__ending">
        Given the evolving nature of technology and privacy regulations, our
        privacy law practitioners stay up-to-date with the latest legal
        developments and industry trends. Aeon Capital Law works with a wide
        range of clients, including businesses, healthcare providers, government
        agencies, and individuals, to ensure that personal information is
        handled appropriately and in compliance with the law. Please click below
        to contact us for a free consultation so we can individually assess your
        case.
      </p>
      <Link to="/contact-us">
        <button className="contact-btn"> Contact Us</button>
      </Link>
    </div>
  );
};

export default PrivacyLaw;
