import React from 'react'

const Intro = () => {
  return (
    <div className="intro">
      <h3 className="intro__heading">Welcome to Aeon Capital Law</h3>
      <div className="intro__contents">
        <p className="intro__text">
          Aeon Capital Law is a specialty law firm that provides effective and
          efficient legal services at an affordable rate for all our clients.
        </p>
        <p className="intro__text">
          “Aeon” translates in the English language to: time. Time is our focus
          in everything we do!
        </p>
        <p className="intro__text">
          Our effectiveness to our clients is how quickly we attend to and
          respond to their matter. The results we achieve for our clients is how
          we measure our impact. <br />
          The firm is focused on five specialty areas of practice:
        </p>
      </div>
    </div>
  );
}

export default Intro