import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/logo/aeonlogo.png";
import "./Header.scss";

function Header() {
  return (
    <Navbar
      bg="gray"
      variant="light"
      expand="lg"
      className="navbar"
      collapseOnSelect
    >
      <Container>
        <Navbar.Brand href="/">
          <img src={Logo} alt="logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto ">
            <Nav.Link href="/about-us">About Us</Nav.Link>
            <Nav.Link href="/our-firm">Our Firm</Nav.Link>
            <NavDropdown title="Practice Areas" id="basic-nav-dropdown">
              <NavDropdown.Item href="/civil-litigation">
                Civil Litigation
              </NavDropdown.Item>
              <NavDropdown.Item href="/corporate-commercial-law">
                Corporate Commercial Law
              </NavDropdown.Item>
              <NavDropdown.Item href="/insurance">
                Insurance Law
              </NavDropdown.Item>

              <NavDropdown.Item href="/estate-law">Estate Law</NavDropdown.Item>
              <NavDropdown.Item href="/privacy-law">
                Privacy Law
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
