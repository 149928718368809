import React from "react";
import { Carousel } from "react-bootstrap";
import "./Testimonial.scss";
const TestimonialSlider = () => {
  return (
    <>
      <h2 className="testimony">The Words of Our Clients</h2>
      <div className="testimonial-container">
        <div className="testimonial-slider">
          <Carousel>
            <Carousel.Item className="testimonial-slide">
              <p className="quote">
                "I want to express my deepest gratitude to Aeon Capital Law for
                their exceptional service in handling my recent law suit against
                my insurance company. The team's expertise, dedication, and
                clear communication made the process smooth and stress-free. I
                am very happy with my settlement. I recommend their services to
                anyone in need of reliable legal representation."
              </p>
              <p className="author">- Nikolas Charlie</p>
            </Carousel.Item>

            <Carousel.Item className="testimonial-slide">
              <p className="quote">
                "I highly recommend Aeon Capital Law for anyone seeking
                top-notch legal support in estate matters. Their expertise and
                attention to detail were evident from the start. The team's
                clear guidance made a complex process more manageable and much
                FASTER!"
              </p>
              <p className="author">- Valary Braylon</p>
            </Carousel.Item>

            <Carousel.Item className="testimonial-slide">
              <p className="quote">
                "I am a business owner who recently had the pleasure of working
                with Aeon Capital Law on a dispute with a vendor. I couldn't be
                more satisfied with their representation. The team's deep
                understanding of business intricacies, prompt responsiveness,
                and strategic counsel have proven invaluable. "
              </p>
              <p className="author">- Precious Trafford</p>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default TestimonialSlider;
