import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Privacy.scss";

const Accordion = ({ title, content, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h6>
          {title} <span className="accordion-icon">{isOpen ? "-" : "+"}</span>
        </h6>
      </div>
      {isOpen && <p>{content}</p>}
    </div>
  );
};

const Privacy = () => {
  return (
    <div className="terms">
      <h2 className="terms__title">Privacy Statement</h2>
      <div className="terms__contents">
        <h4 className="terms__contents--heading">Commitment to privacy</h4>

        <p className="terms__contents--description">
          Aeonlaw.ca ("Aeon Capital Law" or "We") is committed to protecting
          your ("You” or “User”) privacy. We will remain transparent about how
          your personal information is collected, used and disclosed at all
          times. Our Privacy Policy ("Privacy Policy") outlines our information
          practices and how We protect Your right to privacy when You interact
          with Our website (the "Site" or "Our Site").
        </p>
        <p className="terms__contents--description">
          Aeon Capital complies with the Personal Information Protection and
          Electronic Documents Act (“PIPEDA”), Canada’s Anti-Spam Law (CASL) and
          all other applicable federal and provincial privacy legislation.{" "}
          <strong>
            PLEASE CAREFULLY READ THE PRIVACY POLICY TO OPTIMIZE YOUR EXPERIENCE
            ON OUR SITE.
          </strong>
          All suppliers and Users are encouraged to address any questions or
          inquiries to our privacy officer at privacy@aeoncapital.ca.
        </p>

        {/* Consent */}
        <h4 className="terms__contents--heading">Consent</h4>
        <p className="terms__contents--description">
          By visiting and using Our Site, you acknowledge that You have read and
          fully understand our Privacy Policy and freely consent to the
          information practices described herein.{" "}
          <strong>
            IF YOU DO NOT CONSENT TO THE INFORMATION PRACTICES DESCRIBED BELOW,
            YOU MUST IMMEDIATELY CEASE ANY ACCESS OR USE OF OUR SITE.
          </strong>
        </p>
        <h4 className="terms__title">Personal Information</h4>

        <div>
          <Accordion
            title="Collection of Your Personal Information"
            content={
              <>
                <p>
                  Canadian privacy legislation defines personal information as
                  information about an identifiable individual. We collect
                  personal information such as Your name, email address, phone
                  number, work address, business name, professional title and
                  work address. We may collect financial information such as
                  credit card or banking information. Lastly, When You use or
                  visit Our site, information in the form of web server logs is
                  automatically collected. This information may include:
                </p>
                <li>Previously visited website domain names;</li>
                <li>browser name and type</li>
                <li>IP address</li>
                <li>Date, time and duration of usage</li>
                <li>The website address linked from or to or</li>
                <li>
                  the identification of Your Internet Service Provider (ISP).
                </li>
                <p>
                  Aeon Capital Law will never ask for Your username, password,
                  banking information or any other personal information in an
                  unsolicited phone call, email or letter. Additionally, any
                  personal information exchanged with an Aeon Capital Law
                  employee or representative will be limited solely to the
                  purpose to which it was collected.
                </p>
                <p>
                  We collect Your personal information in order to provide You
                  with a quality user experience when using Our Site, and to
                  share relevant information with You in order to enhance Your
                  User experience. Your financial information is only to
                  facilitate any related payments or transactions. We do not
                  sell, lease or rent any of our Users’ personal information nor
                  do We disclose it to unauthorized third parties.
                </p>
              </>
            }
            defaultOpen={true}
          />
          <Accordion
            title="Use and Disclosure of Personal Information"
            content={
              <>
                <p>
                  We use Your personal information to operate the Aeon Capital
                  Law website, to provide You with services related to Our legal
                  service offerings, to process transactions and to provide You
                  with relevant information to improve Your experience on Our
                  site. We may also disclose Your personal information to a
                  government body that has a lawful authority to obtain the
                  information. Additionally, We may disclose Your personal
                  information where there is reasonable grounds to believe the
                  information could be useful: (a) in authorized investigation
                  of unlawful activity; or (b) to comply with a subpoena,
                  warrant, a court order, or a production order for information
                  by an individual or body with authority to compel the
                  production of such information.In addition, We may use Your
                  personal information for the following purposes:
                </p>
                <li>
                  We may use personal information in the form of aggregate data
                  for statistical analysis, to design programs for Users;
                </li>
                <li>
                  to share with Our affiliates in order to: (i) develop and host
                  Our site; (ii) prepare purchase orders; or (iii) process
                  transactions. All such affiliates are prohibited from using
                  Your personal information except to provide these services to
                  You or to Us, and are required to maintain the confidentiality
                  of Your personal information;
                </li>
                <li>
                  to communicate, develop and manage our e-newsletter and other
                  User communication programs;
                </li>
                <li>
                  to provide it to our service providers to use in the course of
                  providing services to Aeon Capital Law;
                </li>
                <li>
                  to comply with federal and provincial statutory and regulatory
                  requirements; or
                </li>
                <li>
                  other purposes which We may suggest or indicate to You from
                  time to time.
                </li>
              </>
            }
            defaultOpen={true}
          />
        </div>

        <h4 className="terms__title">Information Collected</h4>
        <Accordion
          title="Use of Cookies"
          content="A cookie consists of information that is collected by a user’s
          computer whenever the user visits a website. The website sends the
          cookie to the user’s computer where it is stored. Cookies help
          websites keep track of a user’s visits and activities. Cookies can be
          useful in many ways including storing login information, so the user
          does not have to User or re-enter their username or password. As well,
          when shopping online, cookies simplify the process by keeping track of
          a shopper’s shipping or billing address. Cookies cannot be used to
          operate programs or transmit viruses to Your computer. Cookies are
          used to improve Your experience on Our site. You do have the option to
          change the settings of Your cookies through your internet browser
          settings. Changes of Your cookie settings will not prevent You from
          using Our site, however, this may limit the availability of certain
          features on Our site."
          defaultOpen={true}
        />
        <Accordion
          title="Third-Party Websites"
          content="Our affiliates and partners may use their own cookies when You click
          on a hyperlink from Our site to their website or service, We highly
          recommend that You carefully review and understand the privacy policy
          and information practices of these third-party website or services."
          defaultOpen={true}
        />
        <Accordion
          title="Security of Personal Information"
          content="Aeon Capital Law makes every reasonable effort available to protect
          the personal information under Our custody and control against loss,
          theft, unauthorized access, use, disclosure and/or modification. We do
          this through appropriate physical, electronic, policy and procedural
          safeguards to protect the personal information collected through Our
          site. Our internal policies and procedures ensure only authorized
          employees or affiliates of Aeon Capital Law can access Your personal
          information. Your personal information is retained as long as
          necessary to fulfil the identifiable purpose which it was collected
          for. When banking information such as a credit card number is
          transmitted to Our site, it is encrypted through a Secure Socket Layer
          (SSL) protocol. This provides optimal protection of Your banking
          information."
          defaultOpen={true}
        />
        <Accordion
          title="Correction of Personal Information"
          content="Users have the option to update personal information such as their
          billing and payment information should such information change. These
          changes can be done by contacting Aeon Capital Law through our privacy
          officer. Aeon Capital Law will make all reasonable efforts to correct,
          update or remove the personal information provided."
          defaultOpen={true}
        />
        <Accordion
          title="Canada’s Anti-Spam Legislation (“CASL”) "
          content={
            <>
              <p>
                Aeon Capital Law complies with CASL and we are committed to
                making sure individuals only receive the email communications
                that they want from us. Aeon Capital Law will never send
                unsolicited emails in connection with the marketing of Aeon
                Capital Law, its services or products. We occasionally send
                emails to members of mailing lists shared with us from other
                organizations who have received your opt-in consent. Usually,
                this is from industry related groups. We may occasionally
                contact you to notify you about updates to the Website or new
                products or services offered by us, or to deliver targeted
                information that may be of interest to you.
              </p>
              <p>
                {" "}
                No matter how you join our list or provide us with your consent
                to receive communications, at the bottom of all our emails, you
                will find a clear, easy way to unsubscribe. For more information
                about our Anti-Spam Policy or if you have questions or a
                complaint related to CASL, you may contact our CASL Privacy
                Officer immediately at the contact information below.
              </p>
            </>
          }
          defaultOpen={true}
        />

        <h4 className="terms__title">Changes</h4>

        <Accordion
          title="Changes to this Statement"
          content="Aeon Capital reserves the right to change, update, modify or
          supplement the terms of Our Privacy Policy at any time by posting a
          revised Privacy Policy. We encourage You to regularly check Our Site
          for any updates to Our privacy policy."
          defaultOpen={true}
        />

        <h4 className="terms__title">Contact</h4>
        {/* Contact information  */}
        <h6 className="terms__contents--heading">Contact information</h6>
        <p className="terms__contents--description">
          For access to Your personal information or if You have any questions
          or concerns about how we control and protect Your personal
          information, please send us an email at (privacy@aeoncapital.ca) or
          write to our corporate office at:
        </p>
        <p>
          Aeon Capital Law <br /> 2680 Matherson Blvd E Suite 102, <br />{" "}
          Missisauga, ON L4W 0A5 <br /> Phone : (905)306-9898 <br /> Fax:
          (905)306-9898
        </p>
      </div>

      <div className="button">
        <Link to="/terms">
          <button className="button__terms">Terms of Use</button>
        </Link>
        <button className="button__privacy">Privacy Policy</button>
      </div>
    </div>
  );
};

export default Privacy;
