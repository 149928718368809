import React from "react";
import "./About.scss"
import { Carousel } from "react-bootstrap";
import "../../styles/styles.scss"
import ABT from "../../assets/Videos/ACL _ Superior Services _ Main Page _ Second (2).mp4"
//import IMG from "../../assets/images/about.png"


const About = () => {
  return (
    <div className="about">
      <Carousel className="custom-carousel" controls={false}>
        <Carousel.Item>
          <video className="d-block w-100 " autoPlay loop muted>
            <source src={ABT} type="video/mp4" />
          </video>
          <div className="carousel-text">
            <h2>ABOUT US</h2>
          </div>
        </Carousel.Item>
      </Carousel>
      <h2 className="about__heading">AEON CAPITAL LAW</h2>
      <p>
        Achieving the very best results for our clients at an affordable rate
        through effective and zealous representation is the hallmark of our
        firm. We are committed to the application of our skills, experience and
        integrity to provide the very best representation to our clients.
      </p>
      <p>
        We are flexible in our approach. We able to utilize technology to
        provide a boutique-style personalized approach, with the efficacy of a
        mid-size law firm. The results we achieve for our clients before trial
        and in the Courtroom is what attracts our clients to us. The warmth and
        professionalism of our staff is what brings our clients back to us.
      </p>
      <p>
        We provide practical solutions to most complicated matters. In a manner
        and cost that most other firms cannot match. We don’t charge for any
        fees that is not disclosed to our clients beforehand. We take the time
        to explain every step involved in a clients matter to them before we
        embark on any decision or legal strategy.
      </p>
      <p>
        We are familiar with the Courts in most jurisdictions and all level of
        Courts, tribunals and hearing rooms. We look forward to hearing from you
        and demonstrating how we can effectively assist you you’re your matter.
      </p>
    </div>
  );
};

export default About;
