import React, { useState } from "react";
import "../../styles/styles.scss";
import { Carousel } from "react-bootstrap";
import CLi from "../../assets/Videos/Civil Litigation _ Main Page _ Third (3).mp4";
import { Link } from "react-router-dom";

const Section = ({ title, content, isExpanded, onToggle }) => {
  return (
    <div>
      <h6 className="corporate__title" onClick={onToggle}>
        {title} {isExpanded ? "-" : "+"}
      </h6>
      {isExpanded && (
        <ul className="corporate__lists">
          <li className="corporate__list">{content}</li>
        </ul>
      )}
    </div>
  );
};

const Civil = () => {
  const sectionsData = [
    {
      title: "Pre-Litigation",
      content:
        "Demand Letter: Before filing a lawsuit, the plaintiff (the party bringing the case) may send a demand letter to the defendant (the party being sued) outlining the claims and seeking a resolution.",
    },
    {
      title: "Commencement of Proceedings",
      content:
        "Statement of Claim: If the dispute isn't resolved through negotiations, the plaintiff initiates the process by filing a Statement of Claim with the appropriate court. This document outlines the details of the claim, including parties involved, facts, legal basis, and remedies sought.",
    },
    {
      title: "Service of Documents",
      content:
        "Service of the Statement of Claim: The plaintiff serves the Statement of Claim on the defendant, usually through personal service or alternative to personal service.",
    },
    {
      title: "Defendant's Response",
      content:
        "Statement of Defence: The defendant responds to the claim by filing a Statement of Defence, addressing the allegations and presenting their version of events.",
    },
    {
      title: "Pleadings Stage",
      content:
        "Reply and Counterclaim: Depending on the circumstances, the plaintiff might file a Reply to respond to the Statement of Defence.The defendant might also file a Counterclaim if they have their own claims against the plaintiff.",
    },
    {
      title: "Discovery",
      content:
        "Examinations for Discovery: Both parties conduct Examinations for Discovery, during which they can question the other party's witnesses under oath. This helps each side gather information and assess the strengths and weaknesses of their case.",
    },
    {
      title: "Mediation and Settlement Conference:",
      content: (
        <div>
          <p>
            Mediation: The court may require or parties may opt for mediation, a
            process where a neutral third party (the mediator) helps the parties
            negotiate a settlement.,
          </p>
          <li>
            Settlement Conference: If mediation fails or isn't attempted, the
            court may schedule a settlement conference to encourage the parties
            to settle.
          </li>
        </div>
      ),
    },
    {
      title: "Pre-Trial Proceedings:",
      content: (
        <div>
          <p>
            Case Conference: A case conference is held to discuss case
            management and procedural issues.
          </p>
          <li>
            Motions: Parties can file motions to seek court orders on various
            matters, such as evidence admissibility or procedural disputes. The
            most common remedy in a civil suit is monetary in nature and is
            known as damages, however, sometimes injunctive relief or
            declarations can be sought.
          </li>
        </div>
      ),
    },
    {
      title: "Trial",
      content: (
        <div>
          <p>
            Jury Selection (if applicable): Either party can request a trial by
            jury. If so, the jury selection process takes place.
          </p>
          <li>
            {" "}
            Opening Statements: Both sides present their opening statements,
            outlining their case's main points.
          </li>
          <li>
            {" "}
            Presentation of Evidence: Witnesses are called, evidence is
            presented, and legal arguments are made.
          </li>
          <li>
            {" "}
            Closing Arguments: Both sides present their final arguments
            summarizing their case.
          </li>
          <li>
            Judgment: The judge (or jury) renders a decision based on the
            evidence and arguments presented.
          </li>
        </div>
      ),
    },
    {
      title: "Post-Trial:",
      content: (
        <div>
          <p>
            Appeals: Either party can appeal the trial court's decision to a
            higher court if there are grounds for appeal.
          </p>
          <li>
            Enforcement: If the plaintiff wins, they may need to take steps to
            enforce the judgment if the defendant doesn't voluntarily comply.
          </li>
        </div>
      ),
    },

    // Add other sections as needed
  ];

  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };

  return (
    <div className="corporate">
      {/* ... your existing code ... */}
      <Carousel className="custom-carousel">
        <Carousel.Item>
          <video className="d-block w-100" autoPlay loop muted>
            <source src={CLi} type="video/mp4" />
          </video>

          <div className="carousel-text">
            <h2>
              Our experienced civil litigation attorneys are dedicated to
              resolving legal disputes on behalf of our clients.
            </h2>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="corporate__description">
        <h2>CIVIL LITIGATION</h2>
        <p>
          The civil litigation process in Ontario is complex and follows a
          structured series of steps that parties go through to resolve disputes
          through the courts. The general civil litigation process in Ontario
          involves:
        </p>
      </div>
      <div className="corporate__contents">
        {sectionsData.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
            isExpanded={expandedSection === index}
            onToggle={() => toggleSection(index)}
          />
        ))}
      </div>
      <p className="corporate__ending">
        It's important to note that the above process is a general overview and
        may vary depending on the specific circumstances of the case and the
        court in which it is filed. Legal representation is highly recommended
        in civil litigation to navigate the complex process effectively. Please
        contact us for a free consultation so we can individually assess your
        case.
      </p>
      <Link to="/contact-us">
        <button className="contact-btn"> Contact Us</button>
      </Link>
    </div>
  );
};

export default Civil;
