import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "../../styles/styles.scss";
import Est from "../../assets/Videos/Estate Law _ Main Page _ Sixth (6).mp4";
import { Link } from "react-router-dom";

const Section = ({ title, content, isExpanded, onToggle }) => {
  return (
    <div>
      <h6 className="corporate__title" onClick={onToggle}>
        {title} {isExpanded ? "-" : "+"}
      </h6>
      {isExpanded && (
        <ul className="corporate__lists">
          <li className="corporate__list">{content}</li>
        </ul>
      )}
    </div>
  );
};

const EstateLaw = () => {
  const sectionsData = [
    {
      title: "Wills and Estate Planning",
      content: (
        <>
          <p>
            Drafting wills that outline how a person's assets should be
            distributed after their death.
          </p>
          <li>
            Advising clients on estate planning strategies to minimize taxes and
            ensure their wishes are met.
          </li>
        </>
      ),
    },
    {
      title: "Probate and Estate Administration",
      content: (
        <>
          <p>
            Assisting with the probate process, which involves validating the
            will and obtaining court approval for its execution.
          </p>
          <li>
            Administering estates by collecting assets, paying debts, and
            distributing property to beneficiaries
          </li>
        </>
      ),
    },
    {
      title: "Estate Litigation",
      content: (
        <>
          <p>
            Representing policy holders in disputes with insurance companies
            over denied or disputed claims.
          </p>
          <li>
            Representing clients in disputes related to wills, estates, and
            trusts, such as challenges to the validity of a will or claims of
            undue influence.
          </li>
        </>
      ),
    },
    {
      title: "Bad Faith Claims",
      content:
        "Advising policyholders on potential claims against insurers for acting in bad faith, such as unreasonably denying valid claims or delaying claim processing.",
    },
    {
      title: "Powers of Attorney",
      content:
        "Drafting powers of attorney documents that designate individuals to make financial and medical decisions on behalf of the person granting the power.",
    },
    {
      title: "Guardianship and Capacity Issues",
      content:
        "Assisting with legal matters related to guardianship for individuals who are unable to manage their affairs due to incapacity or disability.",
    },
    {
      title: "Estate Dispute Resolution",
      content:
        "Participating in mediation or litigation to resolve disputes among beneficiaries or between beneficiaries and executors or trustees.",
    },
  ];

  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };

  return (
    <div className="corporate">
      <Carousel className="custom-carousel" controls={false}>
        <Carousel.Item>
          <video className="d-block w-100 " autoPlay loop muted>
            <source src={Est} type="video/mp4" />
          </video>
          <div className="carousel-text">
            <h2>
              Plan for the future with our estate planning services, including
              wills, trusts, and probate assistance.
            </h2>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="corporate__description">
        <h2>Estate Law</h2>
        <p>
          Aeon Capital Law estate law practice involves providing legal services
          and advice related to matters concerning estates, estate litigation,
          trusts, and the distribution of assets upon an individual's death. Our
          estate law Practice is focused on helping families carry out the
          wishes of the deceased person as it concerns: property, assets, and
          personal matters as intended. We are specialist in:
        </p>
      </div>
      <div className="corporate__contents">
        {sectionsData.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
            isExpanded={expandedSection === index}
            onToggle={() => toggleSection(index)}
          />
        ))}
      </div>
      <p className="corporate__ending">
        An estate law practice requires a compassionate approach to addressing
        clients' personal and family matters during times of emotional stress.
        Aeon Capital Law works closely with clients to ensure their intentions
        are properly documented and legally enforceable. We navigate complex
        legal and tax considerations to help clients create comprehensive estate
        plans that reflect their wishes and protect their assets for future
        generations. Please contact us for a free consultation so we can
        individually assess your case.
      </p>
      <Link to="/contact-us">
        <button className="contact-btn"> Contact Us</button>
      </Link>
    </div>
  );
};

export default EstateLaw;
