import React, { useState } from "react";
import "../../styles/styles.scss";
import Corp from "../../assets/Videos/Corporate Commercial Law _ Main Page _ Fourth (4).mp4";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

const Section = ({ title, content, isExpanded, onToggle }) => {
  return (
    <div>
      <h6 className="corporate__title" onClick={onToggle}>
        {title} {isExpanded ? "-" : "+"}
      </h6>
      {isExpanded && (
        <ul className="corporate__lists">
          <li className="corporate__list">{content}</li>
        </ul>
      )}
    </div>
  );
};
const CorporateCLaw = () => {
  const sectionsData = [
    {
      title: "Business Formation and Structure",
      content: (
        <div>
          <p>
            Assisting clients in choosing the appropriate business structure,
            such as sole proprietorship, partnership, corporation, or limited
            liability partnership (LLP).
          </p>
          <li>
            Drafting and filing necessary documents to establish and register
            businesses.
          </li>
        </div>
      ),
    },
    {
      title: "Incorporation and Corporate Governance",
      content: (
        <div>
          <p>
            Advising on the process of incorporating a company under the federal
            or provincial laws.
          </p>
          <li>
            Drafting corporate bylaws, shareholder agreements, and partnership
            agreements.
          </li>
          <li>
            Providing guidance on corporate governance best practices and
            compliance with applicable laws and regulations.
          </li>
        </div>
      ),
    },
    {
      title: "Commercial Contracts",
      content: (
        <div>
          <p>
            Drafting, reviewing, and negotiating various commercial contracts,
            including, amongst others: sales agreements, service contracts,
            licensing agreements, and distribution agreements, etc.
          </p>
          <li>Advising on contract interpretation, performance, and breach.</li>
        </div>
      ),
    },
    {
      title: "Intellectual Property (IP) Matters",
      content:
        "Drafting and negotiating IP-related agreements, such as licensing and assignment agreements",
    },
    {
      title: "Commercial Litigation and Dispute Resolution",
      content: (
        <div>
          <p>
            Representing clients in commercial disputes, breach of contract
            cases, and shareholder disputes.
          </p>
          <li>
            Providing pre-litigation and litigation support, including
            negotiation and alternative dispute resolution (ADR) processes.
          </li>
        </div>
      ),
    },
    {
      title: "Corporate Restructuring and Insolvency",
      content:
        "Advising on corporate reorganizations, debt restructuring, and bankruptcy proceedings.",
    },
  ];
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };

  return (
    <div className="corporate">
      <Carousel className="custom-carousel" controls={false}>
        <Carousel.Item>
          <video className="d-block w-100" autoPlay loop muted>
            <source src={Corp} type="video/mp4" />
          </video>
          <div className="carousel-text">
            <h2>
              We protect your business interests with our comprehensive business
              law services, including contracts, disputes, and compliance.
            </h2>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="corporate__description">
        <h4>Corporate Commercial Law</h4>

        <p>
          A corporate commercial law practice in Ontario, Canada, involves
          providing legal services and advice to businesses, corporations, and
          organizations on a wide range of legal matters related to their
          operations, transactions, and regulatory compliance. This area of law
          encompasses various aspects of business law, contract law, corporate
          governance, mergers and acquisitions, and more. Aeon Capital Law can
          help you and your business with:
        </p>
      </div>
      <div className="corporate__contents">
        {sectionsData.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
            isExpanded={expandedSection === index}
            onToggle={() => toggleSection(index)}
          />
        ))}
      </div>
      <p className="corporate__ending">
        A corporate commercial law practice in Ontario requires a deep
        understanding of business operations, legal principles, and regulatory
        frameworks. It involves working closely with clients to provide
        strategic legal advice that supports their business goals while ensuring
        compliance with applicable laws and regulations. Please contact us for a
        free consultation so we can individually assess your case.
      </p>
      <Link to="/contact-us">
        <button className="contact-btn"> Contact Us</button>
      </Link>
    </div>
  );
};

export default CorporateCLaw;
