import React, { useState } from "react";
import "../../styles/styles.scss";
import { Carousel } from "react-bootstrap";
import INS from "../../assets/Videos/InsuranceLaw.mp4";
import { Link } from "react-router-dom";

const Section = ({ title, content, isExpanded, onToggle }) => {
  return (
    <div>
      <h6 className="corporate__title" onClick={onToggle}>
        {title} {isExpanded ? "-" : "+"}
      </h6>
      {isExpanded && (
        <ul className="corporate__lists">
          <li className="corporate__list">{content}</li>
        </ul>
      )}
    </div>
  );
};

const Insurance = () => {
  const sectionsData = [
    {
      title: "Insurance Policies and Contracts",
      content: (
        <div>
          <p>Advising clients on insurance policy terms and coverage.</p>
          <li>
            Reviewing and interpreting insurance contracts to determine the
            scope of coverage, exclusions, and limitations.
          </li>
        </div>
      ),
    },
    {
      title: "Insurance Claims",
      content: (
        <div>
          <p>
            Assisting policyholders in filing insurance claims for various types
            of losses, such as property damage, personal injury, or business
            interruption.
          </p>
          <li>
            Negotiating with insurance companies to ensure policyholders receive
            fair compensation for their claims.
          </li>
        </div>
      ),
    },
    {
      title: "Coverage Disputes",
      content: (
        <div>
          <p>
            Representing policy holders in disputes with insurance companies
            over denied or disputed claims.
          </p>
          <li>
            Litigating coverage disputes and seeking declaratory judgments on
            policy interpretation.
          </li>
        </div>
      ),
    },
    {
      title: "Bad Faith Claims",
      content:
        "Advising policyholders on potential claims against insurers for acting in bad faith, such as unreasonably denying valid claims or delaying claim processing.",
    },
    {
      title: "Insurance Litigation",
      content:
        "Representing clients in insurance-related litigation, including cases involving coverage disputes, bad faith claims, and subrogation.",
    },
    {
      title: "Corporate Restructuring and Insolvency",
      content:
        "Assisting policyholders in complex and high-value claims resulting from catastrophic events, such as natural disasters.",
    },
  ];

  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };
  return (
    <div className="corporate">
      <Carousel className="custom-carousel" controls={false}>
        <Carousel.Item>
          <video className="d-block w-100 " autoPlay loop muted>
            <source src={INS} type="video/mp4" />
          </video>
          <div className="carousel-text">
            <h2>
              We specialize in a wide array of insurance-related matters,
              ensuring that our clients receive fair treatment and just
              compensation.
            </h2>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="corporate__description">
        <h4>Insurance Law</h4>
        <p>
          Aeon Capital Law insurance law practice involves providing legal
          services and advice related to various aspects of insurance, including
          insurance policies, claims, disputes, and regulatory matters. Our
          insurance law practice encompasses a wide range of areas in the
          insurance industry to include:
        </p>
      </div>
      <div className="corporate__contents">
        {sectionsData.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            content={section.content}
            isExpanded={expandedSection === index}
            onToggle={() => toggleSection(index)}
          />
        ))}
      </div>
      <p className="corporate__ending">
        An insurance law practice requires a thorough understanding of insurance
        regulations, industry practices, and legal principles. Aeon Capital Law
        works with insurance companies, policyholders, and other stakeholders to
        ensure that insurance policies are properly understood, claims are
        handled fairly, and disputes are resolved in accordance with the law. We
        also provide proactive advice to prevent insurance-related issues and
        ensure clients are adequately protected. Please contact us for a free
        consultation so we can individually assess your case.
      </p>
      <Link to="/contact-us">
        <button className="contact-btn"> Contact Us</button>
      </Link>
    </div>
  );
};

export default Insurance;
