import React, { useState } from "react";
import "./Terms.scss";
import { Link } from "react-router-dom";

const Accordion = ({ title, content, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h4>
          {title} <span className="accordion-icon">{isOpen ? "-" : "+"}</span>
        </h4>
      </div>
      {isOpen && <p>{content}</p>}
    </div>
  );
};

const Terms = () => {
  return (
    <div className="terms">
      <h2 className="terms__title">Website Terms of Use</h2>
      <p className="terms__date">Effective Date: August 24,2023</p>
      <div className="terms__contents">
        <h4 className="terms__contents--heading">Acceptance of Terms</h4>
        <p className="terms__contents--description">
          By accessing and using Aeon Capital Law’s website (the "Website"), you
          agree to abide by and be bound by these Terms of Use. If you do not
          agree to these terms, please do not use the Website.
        </p>
        <p className="terms__contents--uppercase">
          YOU ACKNOWLEDGE THAT AEON CAPITAL LAW DOES NOT ACCEPT ANY LIABILITY
          FOR YOUR ACCESS TO OR USE OF THIS WEBSITE. YOUR ACCESS TO OR USE OF
          THIS WEBSITE IS AT YOUR RISK SOLELY.
        </p>
        {/* Disclaimer */}
        <h4 className="terms__contents--heading">Disclaimer of Legal Advice</h4>
        <p className="terms__contents--description">
          The content provided on the Website is for informational purposes only
          and does not constitute legal advice. You should not, at any time take
          any information or content on this website and treat it as legal
          advice. Any articles, results from our past cases, articles,
          publications and other materials available on the Website cannot
          guarantee a similar outcome in future representation by our Firm.
          Contact a us directly to discuss your unique case and the legal
          remedies and options available to address your specific legal
          situation.
        </p>

        <div>
          <Accordion
            title="No Warranties"
            content="The information on the Website is provided 'as is' without any warranties, express or implied. Aeon Capital Law makes no representations or warranties of any kind, express or implied, regarding the accuracy, completeness, reliability, suitability, or availability of the content. Your use of this website and its content is at your own risk. To the fullest extent permitted by applicable law, Aeon Capital Law disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. Aeon Capital Law does not warrant that the website will be uninterrupted, error-free, secure, or free from viruses or other harmful components. Any reliance you place on the information provided on this website is strictly at your own discretion and risk. The information provided on this website is not intended to be, and should not be considered, legal or professional advice. You should consult with a licensed lawyer or qualified professional for advice specific to your situation before making any decisions based on the information provided on this website."
            defaultOpen={true}
          />
          <Accordion
            title="Limitation of Liability"
            content="To the fullest extent permissible under applicable law, you hereby agree to release Aeon Capital Law and its representatives from, and in no event shall any or all of Aeon Capital Law or its representatives be liable to you or any other person or entity, for any and all liabilities and damages (including any direct, indirect, incidental, consequential, or punitive damages) arising out of or in connection with your use of the Website."
            defaultOpen={true}
          />

          <Accordion
            title="Third-Party Links"
            content="The Website may contain links to third-party websites for your convenience. Aeon Capital Law is not responsible for nor do we endorse or assume responsibility for the content, privacy practices, or actions of these linked websites. Your interaction with third-party websites is subject to the terms and policies of those websites. We have no control over the content of these websites and do not endorse or guarantee their accuracy. Your use of third-party websites is at your own risk."
            defaultOpen={true}
          />
          <Accordion
            title="Privacy Policy"
            content="All personal information submitted through our Website is handled in accordance with our Privacy Policy. By using the Website, you agree to the terms outlined in our Privacy Policy, which can be found [insert link to Privacy Policy]."
            defaultOpen={true}
          />
          <Accordion
            title="Intellectual Property"
            content="All content on the Website, including text, graphics, logos, images, and software, is the property of Aeon Capital Law and is protected by intellectual property laws. You may not reproduce, distribute, modify, or otherwise use any content without explicit written permission from Aeon Capital Law."
            defaultOpen={true}
          />
          <Accordion
            title="Trademarks"
            content={
              <>
                <p>
                  Unless otherwise indicated, all trademarks, service marks, and
                  trade names used on this Website are the property of Aeon
                  Capital Law or its respective owners. You may not use,
                  reproduce, or modify any such trademarks, service marks, or
                  trade names without the prior written consent of the
                  respective owners.
                </p>
                <p>
                  The use of any third-party trademarks on this website is for
                  informational purposes only and does not imply endorsement or
                  sponsorship by the respective trademark owners. Any
                  unauthorized use of trademarks, service marks, or trade names
                  on this website is strictly prohibited and may be subject to
                  legal action.
                </p>
                <p>
                  If you believe that any content on this website infringes upon
                  your trademark rights, please follow the procedures outlined
                  in our "Intellectual Property Infringement" provision to
                  notify us of the alleged infringement.
                </p>
                <p>
                  Although certain trademarks of third parties may be used by on
                  our Website with permission or under licence, any third party
                  trademarks displayed on our Website does not constitute or
                  imply any relationship or licence between Aeon Capital Law and
                  the owner of said trademark or to imply that Aeon Capital Law
                  endorses the wares, services or business of the owner of the
                  said trademark.
                </p>
              </>
            }
            defaultOpen={true}
          />
          <Accordion
            title="Intellectual Property Infringement"
            content={
              <>
                <p>
                  Aeon Capital Law respects the intellectual property rights of
                  others and expects its users to do the same. If you believe
                  that any content on this website infringes upon your
                  intellectual property rights, please notify us in writing with
                  the following information:
                </p>
                <ol>
                  <li>
                    A description of the copyrighted work or other intellectual
                    property that you claim has been infringed.
                  </li>
                  <li>
                    A description of the infringing content, including its
                    location on the website and other information that is
                    reasonably sufficient for Aeon Capital Law to identify the
                    alleged infringement.
                  </li>
                  <li>
                    Your contact information, including your name, address,
                    phone number, and email address.
                  </li>
                  <li>
                    A statement that you have a good-faith belief that the use
                    of the disputed content is not authorized by the
                    intellectual property owner, its agent, or the law.
                  </li>
                  <li>
                    A statement, made under penalty of perjury, that the
                    information provided in your notice is accurate and that you
                    are the intellectual property owner or authorized to act on
                    behalf of the owner.
                  </li>
                  <li>
                    Your signature (electronic or wet ink) with your contact
                    information (email, address, and telephone number).
                  </li>
                </ol>
                <p>Please send your infringement notice to: </p>
                <p>
                  Aeon Capital Law <br /> 2680 Matherson Blvd E Suite 102,{" "}
                  <br /> Missisauga, ON L4W 0A5 <br />
                </p>
                <p className="email">Email: info@aeoncapital.ca</p>
                <p>
                  We will promptly investigate and take appropriate action if
                  necessary.
                </p>
                <p>
                  By submitting content to this website, you represent and
                  warrant that you have the necessary rights and permissions to
                  do so, and that the content does not infringe upon the rights
                  of any third party.
                </p>
              </>
            }
            defaultOpen={true}
          />
        </div>

        {/* Changes to Terms */}
        <h4 className="terms__contents--heading">Changes to Terms</h4>
        <p className="terms__contents--description">
          Aeon Capital Law reserves the right to modify or update these Terms of
          Use at any time without prior notice. Your continued use of the
          Website after changes are posted constitutes your acceptance of the
          revised terms.
        </p>
        {/* Governing Law */}
        <h4 className="terms__contents--heading">Governing Law</h4>
        <p className="terms__contents--description">
          These Terms of Use shall be governed by and construed in accordance
          with the laws of the province of Ontario, Canada, without regard to
          its conflict of laws principles. Any disputes arising from or related
          to this Agreement, including but not limited to its interpretation,
          validity, and enforcement, shall be subject to the exclusive
          jurisdiction of the courts of the province of Ontario.
        </p>
        {/* Contact Information */}
        <h className="terms__contents--heading">Contact Information</h>
        <p className="terms__contents--description">
          If you have any questions or concerns about these Terms of Use, please
          contact Aeon Capital Law at info@aeoncapital.ca.
        </p>
        {/* </>
        )} */}
        {/* {!showFullContent && (
          <button onClick={handleReadMore} className="read-more-button">
            Read more
          </button>
        )} */}
      </div>
      <div className="buttons">
        <button className="buttons__terms">Terms of Use</button>
        <Link to="/privacy">
          <button className="buttons__privacy">Privacy Policy</button>
        </Link>
      </div>
    </div>
  );
};

export default Terms;
