import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <p>
          &copy; {new Date().getFullYear()} AEON CAPITAL LAW | All Rights
          Reserved
        </p>
        <div className="container__links">
          <Link to="/terms" className="link">
            <p>Terms </p>
          </Link>
          <p>|</p>
          <Link to="/privacy" className="link">
            <p>Privacy</p>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
