import React from "react";
import "./Slider.scss";
import { Carousel } from "react-bootstrap";
import video1 from "../../assets/Videos/port.mp4";
// import image2 from "../../assets/images/legal.jpg";
// import image3 from "../../assets/images/statue.jpg";

const Slider = () => {
  return (
    <Carousel className="custom-carousel" controls={false}>
      <Carousel.Item>
        <video className="d-block w-100" autoPlay loop muted>
          <source src={video1} type="video/mp4" />
        
        </video>
        <div className="carousel-text">
          <h2>
            We specialize in providing top-notch legal services tailored to meet
            your unique needs.
          </h2>
        </div>
      </Carousel.Item>
     
    </Carousel>
  );
};

export default Slider;
